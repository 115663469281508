<template>
  <div ref="promo" class="ct-promo">
    <div class="container text-center mb-6 md:mb-16">
      <template v-if="!onlySticky">
        <div v-if="icon" class="flex items-center flex-col overflow-hidden">
          <img
            :src="icons[iconType]"
            class="h-15 w-auto mb-5"
            :class="{
              'h-auto max-w-md md:max-w-3xl': iconType === 'kandidattest',
            }"
          />
        </div>
        <h2
          class="m-autotext-center max-w-4xl xl:max-w-5xl mx-auto"
          :class="
            small
              ? 'headline-3xs  md:headline-xs'
              : 'headline-3xs  md:headline-s'
          "
        >
          <div
            v-if="header1 && $t(header1)"
            :class="reverseHeadline ? 'font-light uppercase text-gray-700' : ''"
          >
            {{ $t(header1) }}
          </div>
          <div
            v-if="header2 && $t(header2)"
            :class="[
              reverseHeadline
                ? 'uppercase'
                : 'font-light headline-xs leading-tight',
              iconType === 'kandidattest' ? 'headline-xs leading-tight' : '',
            ]"
            v-html="$t(header2)"
          />
        </h2>

        <!-- <div v-if="description" class="text-sm leading-tight my-2 max-w-3xl mx-auto description" v-html="description" /> -->
      </template>
      <div
        v-if="cta"
        class="flex gap-4 xl:gap-6 justify-center items-center mt-6"
        :class="[
          isSticky && ctaIsSticky
            ? 'fixed bottom-16 z-30 left-0 right-0 mb-10 max-w-xs ml-auto mr-auto bg-white p-3 rounded-2xl shadow-filter'
            : onlySticky
            ? 'hidden'
            : 'relative',
          ctaIsSticky ? 'flex-row' : 'flex-col xl:flex-row',
        ]"
      >
        <div>
          <NuxtLink
            v-if="ctaLabel"
            :to="cta"
            data-testid="ct-promo-cta"
            @click="
              sendStats(
                $t(ctaLabel),
                typeof cta === 'string' ? $t(cta) : undefined
              )
            "
          >
            <CommonButton
              v-if="ctaLabel"
              size="large"
              :bg="buttonBg(ctaColor)"
              :text="buttonColor(ctaColor)"
              :border="ctaBorder ? '' : 'border-none'"
            >
              {{ $t(ctaLabel) }}
            </CommonButton>
          </NuxtLink>
        </div>

        <div v-if="secondCta">
          <NuxtLink
            v-if="secondCtaLabel"
            :to="secondCta"
            data-testid="ct-promo-second-cta"
            :external="
              typeof secondCta === 'string' && secondCta.includes('mailto')
            "
            @click="
              sendStats(
                $t(secondCtaLabel),
                typeof secondCta === 'string' && !secondCta.includes('mailto')
                  ? $t(secondCta)
                  : undefined
              )
            "
          >
            <CommonButton
              v-if="secondCtaLabel"
              size="large"
              :bg="buttonBg(secondCtaColor)"
              :text="buttonColor(secondCtaColor)"
              :border="secondCtaBorder ? '' : 'border-none'"
            >
              {{ $t(secondCtaLabel) }}
            </CommonButton>
          </NuxtLink>
        </div>

        <div v-if="thirdCta">
          <NuxtLink
            v-if="thirdCtaLabel"
            :to="thirdCta"
            data-testid="ct-promo-third-cta"
            @click="
              sendStats(
                $t(thirdCtaLabel),
                typeof thirdCta === 'string' ? $t(thirdCta) : undefined
              )
            "
          >
            <CommonButton
              v-if="thirdCtaLabel"
              size="large"
              :bg="buttonBg(thirdCtaColor)"
              :text="buttonColor(thirdCtaColor)"
              :border="thirdCtaBorder ? '' : 'border-none'"
            >
              {{ $t(thirdCtaLabel) }}
            </CommonButton>
          </NuxtLink>
        </div>
      </div>

      <div
        v-if="description"
        class="text-sm leading-tight my-6 max-w-3xl mx-auto description"
        v-html="description"
      />

      <div
        v-if="disclaimer"
        class="body-xs mt-8 max-w-lg mx-auto disclaimer max-w-xl text-gray-700"
        v-html="disclaimer"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import throttle from 'lodash/throttle'
import promoImageAnnouncementUrl from '@/assets/images/promoimage_announcement.png?url'
import promoIconQuizUrl from '@/assets/icons/promoicon_quiz.svg?url'
import promoIconQuizLightUrl from '@/assets/icons/promoicon_quiz_light.svg?url'
import promoIconConversationUrl from '@/assets/icons/promoicon_conversation.svg?url'
import promoIconConversationLightUrl from '@/assets/icons/promoicon_conversation_light.svg?url'
import promoIconPodcastUrl from '@/assets/icons/promoicon_podcast.svg?url'
import promoIconEmailUrl from '@/assets/icons/promoicon_email.svg?url'
import promoIconVoteUrl from '@/assets/icons/promoicon_vote.svg?url'
import promoIconAnnouncementUrl from '@/assets/icons/promoicon_announcement.svg?url'
import promoIconKandidattestUrl from '@/assets/graphic/gr-promo-kt_about_001.png?url'
import promoIconPromotionsUrl from '@/assets/graphic/promoicon_election@2x.png?url'
import type { RouteLocationRaw } from 'vue-router'

export type CtaColor = 'white' | 'grey' | 'blue'

const props = withDefaults(
  defineProps<{
    header1?: string
    reverseHeadline?: boolean
    header2?: string
    cta?: string | object
    ctaLabel?: string
    ctaColor?: CtaColor
    ctaBorder?: boolean
    secondCta?: string | object
    secondCtaLabel?: string
    secondCtaColor?: CtaColor
    secondCtaBorder?: boolean
    thirdCta?: string | object
    thirdCtaLabel?: string
    thirdCtaColor?: CtaColor
    thirdCtaBorder?: boolean
    disclaimer?: string
    description?: string
    iconType?:
      | 'quiz'
      | 'conversation'
      | 'podcast'
      | 'email'
      | 'vote'
      | 'announcement'
      | 'kandidattest'
      | 'promotions'
    image?: string
    icon?: boolean
    small?: boolean
    id?: string
    zone?: string
    ctaType?: string
    ctaIsSticky?: boolean
    onlySticky?: boolean
  }>(),
  {
    reverseHeadline: false,
    ctaColor: 'white',
    secondCtaColor: 'white',
    thirdCtaColor: 'white',
    iconType: 'conversation',
    icon: true,
    ctaType: 'white',
    small: false,
    ctaIsSticky: false,
    onlySticky: false,
  }
)

const indexStore = useIndexStore()
const config = useRuntimeConfig()

const isSticky = ref(false)

const promoRef = ref<HTMLDivElement>()

const computedImage = computed(() => {
  if (props.iconType === 'announcement') {
    return promoImageAnnouncementUrl
  }
  return props.image
})

const icons = computed(() => ({
  quiz: computedImage ? promoIconQuizUrl : promoIconQuizLightUrl,
  conversation: computedImage
    ? promoIconConversationUrl
    : promoIconConversationLightUrl,
  podcast: promoIconPodcastUrl,
  email: promoIconEmailUrl,
  vote: promoIconVoteUrl,
  announcement: promoIconAnnouncementUrl,
  kandidattest: promoIconKandidattestUrl,
  promotions: promoIconPromotionsUrl,
}))

onMounted(() => {
  if (props.ctaIsSticky) {
    window.addEventListener('scroll', scroll)
  }
})

const gtm = useGtm()
const route = useRoute()

function sendStats(label: string, redirectRoute?: RouteLocationRaw) {
  if (!gtm) return
  if (label === 'Interesseret i et samarbejde?') {
    gtm.trackEvent({
      event: 'GA - Event - B2B sales page',
      eventCategory: 'Click',
      eventAction: 'Call To Action',
      eventLabel: '[click button]',
    })
  } else if (label === 'Se stemmeseddel') {
    gtm.trackEvent({
      event: props.ctaIsSticky
        ? 'GA - Event - View stemmeseddel sticky'
        : route.path.includes('resultat')
        ? 'GA - Event - View stemmeseddel after test'
        : 'GA - Event - View stemmeseddel pre test',
      eventCategory: 'Click',
      eventAction: 'Call To Action',
      eventLabel: '[click button]',
    })
  } else if (label === 'Tag testen') {
    gtm.trackEvent({
      event: route.path.includes('valgkort')
        ? 'GA - Event - start taking test stemmeseddel'
        : props.ctaIsSticky
        ? 'GA - Event - start taking test sticky'
        : 'GA - Event - start taking test',
      eventCategory: 'Click',
      eventAction: 'Call To Action',
      eventLabel: '[click button]',
    })
  } else if (label === 'Send os en email') {
    gtm.trackEvent({
      event: 'GA - Event - Contact',
      eventCategory: 'Click',
      eventAction: 'Call To Action',
      eventLabel: '[click button]',
    })
  } else if (label === 'Tag testen igen') {
    gtm.trackEvent({
      event: 'GA - Event - Repeat test',
      eventCategory: 'Click',
      eventAction: 'Call To Action',
      eventLabel: '[click button]',
    })
  } else {
    gtm.trackEvent({
      event: 'promotionClick',
      ecommerce: {
        promoClick: {
          promotions: [
            {
              id: props.id,
              name: props.header1,
              creative: 'promo',
              position: 'full',
            },
          ],
        },
      },
    })
  }

  if (redirectRoute) {
    navigateTo(redirectRoute)
  }
}

function buttonBg(color: CtaColor) {
  switch (color) {
    case 'white':
      return 'bg-white'
    case 'grey':
      return 'bg-gray-300'
    case 'blue':
      return 'bg-darkblue'
  }
}

function buttonColor(color: CtaColor) {
  switch (color) {
    case 'white':
      return 'text-black'
    case 'grey':
      return 'text-black'
    case 'blue':
      return 'text-white'
  }
}

const scroll = throttle(() => {
  if (!promoRef.value) return
  if (props.ctaIsSticky) {
    const scrollPos = document.body.scrollTop || window.pageYOffset

    const promoBottom = promoRef.value.getBoundingClientRect().bottom
    if (scrollPos > promoBottom + 600 && !isSticky.value) {
      isSticky.value = true
    } else if (scrollPos < promoBottom && isSticky.value) {
      isSticky.value = false
    }
  }
}, 50)
</script>

<style lang="scss" scoped>
.text {
  max-width: 750px;
}
</style>

<style lang="scss">
.ct-promo {
  .disclaimer a,
  .description a {
    @apply underline;
  }
}
</style>
